<template>
 <wx-open-launch-weapp
    id="launch-btn"
    username="gh_d2e8c546483e"
    :path="miniUrl"
    >
    <div v-is="'script'" type="text/wxtag-template" style="display: block">
        <div v-is="'style'">
            .btn { 
                width: 100%;
                height: 39px;
                background: #FFA100;
                border-radius: 2px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                box-sizing: border-box;
                border: 0;
                outline: none;
                margin-right: 11px;
                vertical-align: middle;
            }
            .small-btn{
                background: #FFA100;
                width: 118px;
                height: 40px;
                border-radius: 2px;
                box-sizing: border-box;
                border: 0;
                outline: none;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
            .share-box{
                width: 84px;
                border: 0;
                outline: none;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FF2F3D;
                font-size: 15px;
                padding-top: 1px;
            }
            .share-img{
                width: 20px;
                height: 18px;
            }
        </div>
        <button class="share-box" v-if="types === 3">
            <img class='share-img' src='https://img.fanwoon.com/share-fill%402x.png' />
            <span>{{ strTmep }}</span>
        </button>
        <button v-else :class="{'btn': types === 1, 'small-btn': types === 2  }">{{ strTmep }}</button>
    </div>
    </wx-open-launch-weapp>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import { SET_USER, SET_SIGNATURE } from '@/utils/constant'

export default defineComponent({
    props: ['type', 'msg', 'signature', 'jobId', 'companyId'],
  setup (props:any) {
      /* const sig =  localStorage.getItem(SET_SIGNATURE)
      console.log(sig,'haha-----') */
      const sig =  localStorage.getItem(SET_SIGNATURE)
       console.log(sig,'----')
      console.log(props,'----000')
     onMounted(() => {
         //alert(props.signature)
        
        
         if (sig) {
             _initConfig()
         }
     })
     
     let miniUrl = ref('')  
     const strTmep  = computed(() => props.msg )

     const types = computed(() => props.type)

     const _initConfig = () => {
         let res: any = localStorage.getItem(SET_USER)
         res = JSON.parse(res)
         if (props.type === 1) {
            miniUrl.value = '/pages/enterprise/index?companyId=' + props.companyId //+ res.user.companyId
         }if (props.type === 2 || props.type === 3) {
            miniUrl.value = '/pages/jobDetail/index?id=' + props.jobId //+ res.user.companyId
         }
     }

    
    return {
      miniUrl,
      strTmep,
      types
    }
  }
})
</script>
<style scoped>
#launch-btn{
    width: 100%;
    display: inline-block;
}
</style>