
import { defineComponent, onMounted, ref, computed } from 'vue'
import { SET_USER, SET_SIGNATURE } from '@/utils/constant'

export default defineComponent({
    props: ['type', 'msg', 'signature', 'jobId', 'companyId'],
  setup (props:any) {
      /* const sig =  localStorage.getItem(SET_SIGNATURE)
      console.log(sig,'haha-----') */
      const sig =  localStorage.getItem(SET_SIGNATURE)
       console.log(sig,'----')
      console.log(props,'----000')
     onMounted(() => {
         //alert(props.signature)
        
        
         if (sig) {
             _initConfig()
         }
     })
     
     let miniUrl = ref('')  
     const strTmep  = computed(() => props.msg )

     const types = computed(() => props.type)

     const _initConfig = () => {
         let res: any = localStorage.getItem(SET_USER)
         res = JSON.parse(res)
         if (props.type === 1) {
            miniUrl.value = '/pages/enterprise/index?companyId=' + props.companyId //+ res.user.companyId
         }if (props.type === 2 || props.type === 3) {
            miniUrl.value = '/pages/jobDetail/index?id=' + props.jobId //+ res.user.companyId
         }
     }

    
    return {
      miniUrl,
      strTmep,
      types
    }
  }
})
