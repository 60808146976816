import { defineComponent } from 'vue'
import './index.less'

export default defineComponent({
    name: 'TopHeader',
    setup () {
        return () => (
            <div class="top-header-page flex-box justify-content-between">
                <div>职位管理</div>
                <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
            </div>
        )
    }
})