import { 
    defineComponent, 
    ref, 
    reactive, 
    getCurrentInstance, 
    ComponentInternalInstance, 
    onMounted 
} from 'vue'
import TobHeader from '@/components/header/index'
import { Dialog } from 'vant';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { listOpenLikeName, listCloseLikeName, closeJob, openJob, initSignature } from '@/api/index'
import { SET_HIGHLIT, SET_PUBLISH1, SET_PUBLISH2, SET_SIGNATURE, SET_USER } from '@/utils/constant'
import { delay } from '@/utils/index'
import openMini from '@/components/openMini.vue'

const wx = require("weixin-js-sdk")

import './index.less'

const info_pic = require('@/assets/img/info.png')
const open_pic = require('@/assets/img/open.png')

export default defineComponent({
    name: 'Job',
    components: {
        openMini
    },
    setup () {

        onMounted(() => {
            handleListOpen()
            _clearLocal()
            delay(() => {
                _initJS()
                _checkData()
            }, 500)
        })

        const store = useStore()
        const router = useRouter()
        const active = ref(0)
        const companyId = ref(0)
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties

        const job = reactive({
            data: [],
            pageNum: 1,
            pageSize: 10,
            totalPage: 1,
            totalRecord: 0,
            next: true,
            noData: false, // 无数据
        })
        const loading = ref(false)
        const finished = ref(false)
        const signature = ref(null)

        const _checkData = () => {
            job.data.length > 0 ? job.noData = false : job.noData = true
        }

        const _initJS = async () => {
            initSignature()
            const local: any = localStorage.getItem(SET_SIGNATURE)
            let data: any = localStorage.getItem(SET_USER)
            if (local) {
                signature.value = local
            }
            if (data) {
                data = JSON.parse(data)
                companyId.value = data.user.companyId
            }
        }

        const _clearLocal = () => {
            localStorage.removeItem(SET_HIGHLIT)
            localStorage.removeItem(SET_PUBLISH1)
            localStorage.removeItem(SET_PUBLISH2)
        }

       const _reload = () => {
           job.data = []
           job.pageNum = 1
           job.pageSize =  10
           job.totalPage = 1
           job.totalRecord = 0
           job.next = true
           loading.value = false
           finished.value = false
       } 
        
        const onLoad = () => {
            //debugger
  
            // 数据全部结束
            if (job.data.length >= job.totalRecord) {
                finished.value = true
                //加载状态结束
                loading.value = false 
                console.log('进来了啊。')
            }
           
            let timer: any = setTimeout(() => {
                clearTimeout(timer)
                timer = null
                
                //加载状态结束
                loading.value = true 
                // 数据全部结束
                if (job.data.length >= job.totalRecord && job.data.length != 0) {
                    loading.value = false 
                    finished.value = true
                }

                if (!job.next)return
                const start = (job.pageNum + 1) * job.pageSize - job.pageSize
                active.value === 0 ? handleListOpen(10, start) : handleListCloseLikeName(10, start)
                

            },1000)
        }

        //已关闭职位
        const handleListCloseLikeName = async(limit = 10, start = 0, params = {}) => {

            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                loading.value = false 
                finished.value = true
                return 
            }
            job.next = false
            const res: any = await listCloseLikeName({ limit, start, data: params });
            job.data =  job.data.concat(res.data);
            job.pageNum = res.pageNum 
            job.pageSize = res.pageSize
            job.totalPage = res.totalPage
            job.totalRecord = res.totalRecord
            job.next = true
            job.data.length > 0 ? job.noData = false : job.noData = true
            console.log(res, '关闭职位.')
         
        }

        //招聘中的职位
        const handleListOpen = async(limit = 10, start = 0, params = {}) => {

            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                loading.value = false 
                finished.value = true
                return 
            }
            job.next = false
            const res: any = await listOpenLikeName({ limit, start, data: params })
            job.data = job.data.concat(res.data);
            console.log(job.data.length, '里面看看。。')
            job.pageNum = res.pageNum 
            job.pageSize = res.pageSize
            job.totalPage = res.totalPage
            job.totalRecord = res.totalRecord
            job.next = true
            job.data.length > 0 ? job.noData = false : job.noData = true

            console.log(res, '招聘职位.')
        }
       
        const onPublishPage = () => {
            router.push({path: '/publish'})
        }

        // 开启职位
        const handleOpenJob = async (id: number) => {
            const res: any = await openJob({data: id})
            if (res.code === 0) {
                _reload()
                handleListCloseLikeName()
                return proxy.$toast('职位开启成功!') 
            } else {
                proxy.$toast('职位开启失败!')
            }
        }

        // 关闭职位
        const handleCloseJob = async (id: number) =>{
            const res: any = await closeJob({data: id})
            if (res.code === 0) {
                _reload()
                handleListOpen()
                return proxy.$toast('职位关闭成功!');
            } else {
                proxy.$toast('职位关闭失败!');
            }
        }
       
        // 开启职位后，求职者可以投递简历
        //打开关闭简历弹框
        const onOpenDialog= (num: number, id: number, e: any): void => {
            e.stopPropagation() //阻止冒泡
            //关闭简历弹框
            const closeUI = () => {
                return (
                    <div>
                        <img class="w64" src={info_pic} />
                        <div class="info-font">
                            { num === 0 ? '关闭职位后，将无法接收简历' : '开启职位后，求职者可以投递简历' }
                         </div>
                    </div>
                )
            }

            Dialog.confirm({
                message: closeUI,
                closeOnClickOverlay: true,
            }).then(() => {
                active.value  === 1 ? handleOpenJob(id) : handleCloseJob(id)
                console.log('dayinl')
            }).catch(() => {
                // on cancel
                console.log('cancel')
            });
        }

        // 切换选项卡
        const onClickTab = (val: number, title: any) => {
            console.log(val, title)
            active.value = val
            _reload()
            val === 1 ? handleListCloseLikeName() : handleListOpen()
        }

        const onOpenEditePage = (id: number, e: any) => {
            e.stopPropagation() //阻止冒泡
            router.push({path: '/publish', query: { id: id }})
        }


        // 查看详情.
        const onGoDetails = (e: any) => {
            console.log(e)
            router.push({path: '/info', query: { id: e.id }})
        }


        //招聘中要显示的icon
        const openIconUI = (id: number) => {
            return (
                <span class='item-btn-bar'>
                    <span class="inline wr51" onClick={(e) => onOpenEditePage(id, e)}>
                        <img class="w22" src='https://img.fanwoon.com/bianji%402x.png' />
                        <span>编辑</span>
                    </span>
                    <span class="inline wr51" onClick={(e) => e.stopPropagation() }>
                        <openMini type={3} msg='转发' signature={signature.value} jobId={ id } />    
                    </span>
                    <span class="inline close-icon" onClick={(e) => onOpenDialog(0, id, e)}>
                        <img class="w22" src='https://img.fanwoon.com/guanbi%402x.png' />
                        <span>关闭</span>
                    </span>
                </span>
            )
        }

        //已关闭职位要显示的icon
        const closeIconUI = (id: number) => {
            return (
                <span class="inline" onClick={(e) => onOpenDialog(1, id, e)} >
                    <img class="w22" src={open_pic}  />开启
                </span>
            )
        }

        /**
         *  默认发布职位UI
         */
        const defaultPublishUI = () => {
            return (
                <div class="default-container-box">
                    <img class="pic-item" src='https://img.fanwoon.com/default2.png' />
                    <button onClick={ onPublishPage } class="btn-item">立即发布职位</button>
                    <div class="font-box">无职位</div>
                </div>
            )
        }

        /**
         * 职位类型列表
         */
        const jobListUI = () => {
            return (
                <div class="content-box">
                    <van-list 
                        v-model={ loading.value } 
                        finished={ finished.value }
                        finished-text={ job.data.length === 0 ? '' : '' }
                        onLoad={ onLoad }
                        >
                            {
                                job.data.map((item: any) => {
                                return <div class="bar-item" key={item.id} onClick={ ()=> onGoDetails(item) }>
                                        <div class="flex-box justify-content-between">
                                            <div>
                                                <div class="job-name">{ item.name }</div>
                                                <div class="area-item">
                                                    { item.district }|
                                                    { item.workExperienceName === '不限' ? '经验不限' :  item.workExperienceName} ｜
                                                    { item.education === '不限' ?  '学历不限' :  item.education}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="money-item">
                                                    {
                                                        (!item.salaryMin || !item.salaryMax) ? '面议' : `${ item.salaryMin } - ${ item.salaryMax }/月`
                                                    }
                                                </div>
                                                <div class="time-item">{ item.createTime }</div>
                                            </div>
                                        </div>
                                        <div class="icon-item">
                                            {   active.value === 0 ? openIconUI(item.id) :  closeIconUI(item.id)}
                                        </div>
                                    </div>
                                })
                            }
                    </van-list>
                </div>
            )
        }

        return () => (
            <div class="job-page">
                <TobHeader />
                <van-tabs v-model={active.value} onClick={onClickTab}>
                    <van-tab title="招聘中"></van-tab>
                    <van-tab title="已关闭"></van-tab>
                </van-tabs>


                <div class="content-container-box">
                    {
                        job.data.length === 0 && job.noData ? defaultPublishUI() : ''
                    }
                    {
                        job.data.length === 0 ? '' : jobListUI()
                    }

                    {
                        job.data.length === 0 ? null : (
                            <div class="footer-bar">
                                <div class="footer-container-bar">
                                    <div class="left-btn">
                                        <openMini type={1} msg='邀请员工内推' signature={signature.value} companyId={ companyId.value } /> 
                                    </div>
                                    <button class="right-btn" onClick={onPublishPage}>发布职位</button>
                                </div>
                            </div>
                        )
                    }
                </div>
               
            </div>
        )
    }
})